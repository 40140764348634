const nationalFreightModalityData = [
    {
        label: "Vendemmia Transporte",
        value: "VENDEMMIA_TRANSPORTE"
    },
    {
        label: "Outros",
        value: "OUTROS"
    }
];

export default nationalFreightModalityData;